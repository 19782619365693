import HttpClient from "shared/repositories/HttpClient";
import { Pagination, TelWaitingFortuneTellerRes } from "shared/types";
import useSWRInfinite from "swr/infinite";

export const useTelWaitingFortuneTellerList = (params?: Pagination) => {
  const perPage = params?.limit || 10;
  const fetcher = async (url: string) =>
    await HttpClient.miror
      .get<TelWaitingFortuneTellerRes>(url)
      .then((res) => res.data);

  const getKey = (
    pageIndex: number,
    previousPageData: TelWaitingFortuneTellerRes
  ) => {
    // fetchした結果、データが存在せず空配列が返ってきたとき
    const isEmpty =
      previousPageData && !previousPageData.telWaitingFortuneTellers.length;

    if (isEmpty) return null;

    let url = `/tel_waiting_fortune_tellers`;
    url += `?page=${pageIndex + 1}&limit=${perPage}`;

    return url;
  };

  const swr = useSWRInfinite(getKey, fetcher);

  return {
    ...swr,
    isLoading: swr.data?.length > 0 && swr.isValidating,
  };
};
