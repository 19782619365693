import { PaginationMetaDataV2 } from "../@types";

export const defaultPaginationMeta: PaginationMetaDataV2 = {
  limit: 30,
  pages: 1,
  page: 1,
  nextPage: null,
  prevPage: null,
  firstPage: true,
  lastPage: true,
  outOfRange: false,
  total: 0,
};

export const days = ["日", "月", "火", "水", "木", "金", "土"];
