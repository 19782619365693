import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface SequenceOptions {
  initialDelay?: number;
  delay?: number;
  auto?: boolean;
}

export const useSequence = (
  sequences: (() => void)[],
  { initialDelay = 0, delay = 0, auto = false }: SequenceOptions
) => {
  const [currentSequence, setCurrentSequence] = useState(0);
  const [finishedSequence, setFinishedSequence] = useState([]);

  // eslint-disable-next-line no-undef
  const timer = useRef<NodeJS.Timeout>();

  const sequence = useMemo(
    () => sequences[currentSequence],
    [sequences, currentSequence]
  );

  const isFinished = useMemo(
    () => finishedSequence.some((s) => s === currentSequence),
    [finishedSequence, currentSequence]
  );

  const execNextSequence = useCallback(() => {
    if (sequences.length - 1 > currentSequence) {
      setCurrentSequence(currentSequence + 1);
    }
  }, [sequences, currentSequence]);

  const execSequence = useCallback(() => {
    if (sequence && !isFinished) {
      setFinishedSequence([...finishedSequence, currentSequence]);
      sequence();

      if (auto) execNextSequence();
    }
  }, [
    sequence,
    isFinished,
    finishedSequence,
    currentSequence,
    auto,
    execNextSequence,
  ]);

  const resetSequenceFrom = useCallback((sequenceIndex: number) => {
    if (sequenceIndex === 0) {
      setCurrentSequence(sequenceIndex);
      setFinishedSequence([]);
    } else {
      setCurrentSequence(sequenceIndex);
      setFinishedSequence([...Array(sequenceIndex - 1)]);
    }
  }, []);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (currentSequence === 0) {
      timer.current = setTimeout(execSequence, initialDelay || delay);
    } else {
      timer.current = setTimeout(execSequence, delay);
    }
  }, [currentSequence, execSequence, initialDelay, delay]);

  return {
    execNextSequence,
    resetSequenceFrom,
  };
};
