export const thanksOrderCoupons = [
  {
    name: "鑑定通話終了3時間以内1000円OFFクーポン",
  },
  {
    name: "電話鑑定申込完了3時間以内1000円OFFクーポン",
  },
];

export const dailyBonusCoupons = [
  {
    name: "電話占い最大5分無料！デイリーボーナスクーポン",
  },
];
