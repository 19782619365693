import { css } from "twin.macro";
import { BaseModalProps, BaseModal } from "./BaseModal";
import { Button } from "../../atoms/Button";
import { ReactNode } from "react";

const gradientCss = css({ background: "radial-gradient(#FF4CE1, #FF9892)" });

export interface RadialGradientModalProps extends BaseModalProps {
  headerDecoration?: ReactNode;
  headerTitle: ReactNode;
  headerDescription: ReactNode;
  bodyTitle: ReactNode;
  bodyDescription: ReactNode;
  buttonLabel: ReactNode;
  onClickButton: () => void;
}

export const RadialGradientModal = ({
  headerDecoration,
  headerTitle,
  headerDescription,
  bodyTitle,
  bodyDescription,
  buttonLabel,
  onClickButton,
  onDismiss,
  ...modalProps
}: RadialGradientModalProps) => {
  return (
    <>
      <BaseModal backgroundOnly onDismiss={onDismiss} {...modalProps}>
        <section
          tw="py-8 px-4 rounded-t-3xl font-semibold text-white text-center"
          css={gradientCss}
        >
          {headerDecoration}

          <h3 tw="text-[25px] mt-[14px] whitespace-nowrap">{headerTitle}</h3>
          <p tw="text-[11px] mt-[14px]">{headerDescription}</p>
        </section>

        <section tw="py-8 px-4 rounded-b-3xl bg-white text-center">
          <h4 tw="font-base text-base tracking-[.6px]">{bodyTitle}</h4>
          <p tw="font-light text-2xs leading-5 text-black mt-3">
            {bodyDescription}
          </p>
          <Button tw="w-full bg-[#FE4FD0] h-14 mt-6" onClick={onClickButton}>
            {buttonLabel}
          </Button>
        </section>
      </BaseModal>
    </>
  );
};
