import {
  thanksOrderCoupons,
  dailyBonusCoupons,
} from "features/shared/constants";
import { UserCoupon } from "shared/models";
import { FortuneTeller } from "shared/types";

export const selectThanksOrderCoupons = (coupons: UserCoupon[] | undefined) => {
  return coupons.filter((coupon) => {
    return thanksOrderCoupons.find(
      (campaignCoupon) => campaignCoupon.name === coupon.name
    );
  });
};

export const selectDailyBonusCoupons = (coupons: UserCoupon[] | undefined) => {
  return coupons.filter((coupon) => {
    return dailyBonusCoupons.find(
      (dailyBonusCoupon) => dailyBonusCoupon.name === coupon.name
    );
  });
};

export const selectAvailableCoupons = (
  coupons: UserCoupon[],
  fortuneTeller: Pick<FortuneTeller, "id"> | undefined
) => {
  if (!fortuneTeller) return [];

  return coupons.filter((coupon) => {
    if (coupon.couponRestrictionTypes.length === 0) return true;

    return coupon.couponRestrictionTypes.some((restriction) => {
      const forFortuneTeller = restriction.name === "fortune_teller";
      const isAvailableFortuneTeller =
        restriction.couponRestrictionColumns.some(
          (column) =>
            column.value ===
            fortuneTeller[column.name as keyof Pick<FortuneTeller, "id">]
        );
      return forFortuneTeller && isAvailableFortuneTeller;
    });
  });
};
