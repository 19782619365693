import { useModal } from "mui-modal-provider";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import { useRef } from "react";
import { BaseModalProps } from "shared/components/organisms/modal/BaseModal";
import { RadialGradientModal } from "shared/components/organisms/modal/RadialGradientModal";
import { Paths } from "utils/routes";
import CouponIcon from "@assets/icons/WhiteCoupon.svg";
import { useRouter } from "next/router";
import { useTelWaitingFortuneTellerList } from "repositories/hooks/useTelWaitingFortuneTellerList";
import { defaultPaginationMeta } from "shared/constants";

export const useThanksOrderCouponModal = () => {
  const { showModal } = useModal();
  const modal = useRef<ShowFnOutput<BaseModalProps>>();
  const { push } = useRouter();
  const { data } = useTelWaitingFortuneTellerList();
  const meta = data?.[data?.length - 1]?.meta || defaultPaginationMeta;

  const showThanksOrderCouponModal = (options?: BaseModalProps) => {
    modal.current = showModal(RadialGradientModal, {
      ...options,
      headerDecoration: (
        <CouponIcon tw="mx-auto" css={{ transform: "rotate(1deg)" }} />
      ),
      headerTitle: <h3 tw="text-[21px]">クーポンが付与されました</h3>,
      headerDescription: "3時間以内の電話お申込で1000円分が無料に🎉",
      bodyTitle: `現在、${meta.total}人の占い師が待機中📞`,
      bodyDescription:
        "お得なクーポンを使って、今すぐ相談できる待機中の占い師さんに悩みを聞いてもらいましょう！",
      buttonLabel: "今すぐ相談できる占い師",
      onClickButton: () => {
        push(Paths.fortuneTellers.list.telWaiting());
      },
      onDismiss: () => {
        modal.current?.destroy();
        options?.onDismiss?.();
      },
    });
    return modal.current;
  };

  return {
    showThanksOrderCouponModal,
  };
};
