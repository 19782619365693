import { useUserCoupons } from "repositories/hooks";
import { selectThanksOrderCoupons } from "../utils/coupon";

export const useThanksOrderCoupon = () => {
  const { validCoupons, isLoading } = useUserCoupons();

  const thanksOrderCoupons = selectThanksOrderCoupons(validCoupons);

  return {
    thanksOrderCoupons,
    isLoading,
  };
};
